@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.cursor {
  font-size: 17px; /* match with text size */
  line-height: 1;
  vertical-align: middle;
  animation: blink 1s infinite;
}

.typing-effect {
  display: flex;
  align-items: center;
}
