
.projectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

#projectsTitle{
  margin-top: 120px;
  font-family: 'Lexend Tera', sans-serif;
  font-size: 2rem;
}
.individualProject{
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  margin-right: 50px;
  padding: .5rem;
  border-radius: 15px;
  margin-bottom: 15px;
  font-family: 'Oxygen', sans-serif;
  color: var(--text);
  background-color: var(--background);
}

.projectInfo a{
  display: flex;
  align-items: center;
  justify-content: center;
}

#githubIcon{
  height: 40px;
  width: 40px;
  display: inline-block;
  height: 40px;
  width: 40px;
  vertical-align: text-top;
  margin-left: -20vh;
  margin-top: 5px;
}


#wtcLink{
  height: 150px;
  width: 150px;
}

#awnLink, #tastingNotesLink{
  height: 140px;
  width: 140px;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 12px;
  margin-left: 5px;
}

.iconContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  padding: 10px;
}


.techIcon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 10px;
}

@media (max-width: 675px){
  .projectContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  .individualProject{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    color: var(--text);
  background-color: var(--background);
    padding: .5rem;
    border-radius: 15px;
    margin-bottom: 15px;
    font-family: 'Oxygen', sans-serif;
  }
  #wtcLink{
    height: 150px;
    width: 150px;
    margin-bottom: -10px;
  }
  #projectsTitle{
    margin-top: 120px;
    font-family: 'Lexend Tera', sans-serif;
    font-size: 2rem;
  }
  .projectInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .projectInfo a{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .projectName{
    margin-top: -1px;
    margin-bottom: -5px;
  }
  #githubIcon{
    height: 40px;
    width: 40px;
    display: inline-block;
    height: 40px;
    width: 40px;
    vertical-align: text-top;
    margin-left: 0vh;
    margin-top: 5px;
  }
}
