#titleName, #sentencesTyped{
  color: var(--text-color);
}


.homeContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

#titleName{
  display: flex;
  align-items: center;
  margin-top: 160px;
  justify-content: center;
  font-size: 30px;
  font-family: 'Lexend Tera', sans-serif;
}
#sentencesTyped{
font-size: 16px;
font-family: 'Lexend Tera', sans-serif;
}


@media (max-height: 750px){
  #titleName{
    display: flex;
    align-items: center;
    margin-top: 120px;
    justify-content: center;
    font-size: 30px;
    font-family: 'Lexend Tera', sans-serif;
  }
}
