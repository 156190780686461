@keyframes flyBird {
  to {
    transform: translateX(100vw) translateY(-50vh);
  }
}

.contactContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

}

.contactTitle{
  margin-top: 15vh;
  font-family: 'Lexend Tera', sans-serif;
  font-size: 2rem;
}
.reachOut{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Oxygen', sans-serif;
}

.reachOut{
  font-family: 'Oxygen', sans-serif;
}
.contactIcons{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#lI{
  width: 40px;
  height: auto;
  margin-bottom: 20px;
}
#githubIconContact{
  width: 40px;
  height: auto;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: -4px;
}

#downloadResume{
  margin-bottom: 20px;
  width: 175px;
	padding: 8px 18px;
	border: 1px solid #5d5d5d;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	background: linear-gradient(145deg, #333, #222);
	color: #fff;
	font-weight: bold;
	transition: all 0.3s ease;
  border-radius: 15px;
}

#downloadResume:hover {
	background: linear-gradient(145deg, #555, #444);
}
#downloadResume:active {
	background: linear-gradient(145deg, #222, #111);
}

.emailContainer form{
  display: flex;
  flex-direction: column;
  color: var(--text);
  background-color: var(--background);
  padding: 30px;
  border-radius: 20px;
  align-items: center;
}
.yourName{
  width: 300px;
  font-family: 'Oxygen', sans-serif;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 214, 170, 0.8);
  background-color: rgba(255, 245, 230, 0.8);
  color: #2d2d2d;
}

.yourEmail{
  width: 300px;
  font-family: 'Oxygen', sans-serif;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 214, 170, 0.8);
  background-color: rgba(255, 245, 230, 0.8);
  color: #2d2d2d;
}
.yourMessage{
  width: 300px;
  font-family: 'Oxygen', sans-serif;
  min-height: 100px;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 214, 170, 0.8);
  background-color: rgba(255, 245, 230, 0.8);
  color: #2d2d2d;

}

#submitEmail{
  width: 75px;
  margin-top: 10px;
	padding: 8px 18px;
	border: 1px solid #5d5d5d;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	background: linear-gradient(145deg, #333, #222);
	color: #fff;
	font-weight: bold;
	transition: all 0.3s ease;
  border-radius: 15px;
}

#submitEmail:hover {
	background: linear-gradient(145deg, #555, #444);
}
#submitEmail:active {
	background: linear-gradient(145deg, #222, #111);
}
@media (max-width: 768px){
  .emailContainer form, .yourName, .yourEmail, ::placeholder{
    color: black;
  }
}

@media (max-width: 410px){
  .emailContainer form, .yourName, .yourEmail, ::placeholder{
    color: black;
  }
  .contactContainer form{
    padding-left: 5px;
    padding-right: 5px;
  }
}
